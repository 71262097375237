<template>
  <div>
    <b-card style="margin-bottom: 10px">
      <validation-observer ref="loginForm" #default="{ invalid }">
        <b-form ref="form" :style="{ height: trHeight }" class="repeater-form">
          <b-row>
            <b-col md="6">
              <b-form-group :label="$t('repeaters.Host')">
                <validation-provider
                  #default="{ errors }"
                  name="Host"
                  vid="Name"
                  :rules="{
                    required: true
                  }"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="
                      errors.length > 0 || isInvalidHost ? 'is-invalid' : null
                    "
                  >
                    <b-form-input
                      id="item-name"
                      type="text"
                      @input="validHost"
                      :disabled="
                        !checkAbility({
                          action: constants.PERMISSIONS_ACTION.ADD,
                          subject: constants.PERMISSIONS_MODEL.REPEATERS
                        })
                      "
                      :class="
                        errors.length == 0 && repeater.host && !isInvalidHost
                          ? 'is-valid'
                          : null
                      "
                      :state="errors.length > 0 ? false : null"
                      v-model="repeater.host"
                      :placeholder="$t('repeaters.host_placeholder')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0]
                      ? handleError(errors[0])
                      : isInvalidHost
                      ? $t("repeaters.InvalidHostMessage")
                      : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('repeaters.Port')"
                v-if="repeater.protocol != 'MQTT'"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Port"
                  vid="Port"
                  :rules="{
                    required: repeater.protocol != 'MQTT'
                  }"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="item-name"
                      type="number"
                      :disabled="
                        !checkAbility({
                          action: constants.PERMISSIONS_ACTION.ADD,
                          subject: constants.PERMISSIONS_MODEL.REPEATERS
                        })
                      "
                      :class="
                        errors.length == 0 && repeater.port ? 'is-valid' : null
                      "
                      :state="errors.length > 0 ? false : null"
                      v-model="repeater.port"
                      :placeholder="$t('repeaters.port_placeholder')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0] ? handleError(errors[0]) : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group :label="`${$t('repeaters.Topic')} *`" v-else>
                <validation-provider
                  #default="{ errors }"
                  name="Topic"
                  vid="Topic"
                  :rules="{
                    required: repeater.protocol == 'MQTT'
                  }"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="item-name"
                      type="text"
                      :disabled="
                        !checkAbility({
                          action: constants.PERMISSIONS_ACTION.ADD,
                          subject: constants.PERMISSIONS_MODEL.REPEATERS
                        })
                      "
                      v-model="repeater.topic"
                      :placeholder="$t('repeaters.topic_placeholder')"
                  /></b-input-group>
                  <small class="text-danger">{{
                    errors[0] ? handleError(errors[0]) : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" class="select-protocol">
              <b-form-group :label="$t('repeaters.Protocol')">
                <validation-provider
                  #default="{ errors }"
                  name="protocol"
                  vid="protocol"
                  :rules="{
                    required: true
                  }"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <v-select
                      v-model="repeater.protocol"
                      label="title"
                      :clearable="false"
                      :disabled="
                        !checkAbility({
                          action: constants.PERMISSIONS_ACTION.ADD,
                          subject: constants.PERMISSIONS_MODEL.REPEATERS
                        })
                      "
                      :options="available_protocols"
                      :placeholder="$t('repeaters.protocol_placeholder')"
                  /></b-input-group>
                  <small class="text-danger">{{
                    errors[0] ? handleError(errors[0]) : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="1"
              class="mb-50 mobile-view-delete"
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.ADD,
                  subject: constants.PERMISSIONS_MODEL.REPEATERS
                })
              "
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="danger"
                class="btn-icon rounded-circle mr-10-1 mr-t-28"
                @click="deleteRepeaters(index, repeater.id)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-col>
            <b-col md="5" class="mb-50">
              <RepeaterTests
                v-if="
                  repeater.id &&
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.ADD,
                    subject: constants.PERMISSIONS_MODEL.REPEATERS
                  })
                "
                :selectedAccount="selectedAccount"
                :id="repeater.id"
              ></RepeaterTests>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <b-col cols="12">
              <div>
                <div
                  class="d-flex align-items-center radio-row repeater-row-radio"
                >
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="repeater.selected_units_or_groups.unit_choice"
                      name="some-radios"
                      value="all"
                    >
                      {{ $t("repeaters.All_Units") }}
                    </b-form-radio>
                    <b-form-radio
                      v-model="repeater.selected_units_or_groups.unit_choice"
                      name="some-radios"
                      value="selected"
                    >
                      {{ $t("repeaters.Select_Units") }}
                    </b-form-radio>
                  </div>
                  <div
                    class="ww-50 mt-10 repeater-select-group"
                    v-if="
                      repeater.selected_units_or_groups.unit_choice != 'all'
                    "
                  >
                    <NotificationUnitsSelection
                      :isEditable="
                        checkAbility({
                          action: constants.PERMISSIONS_ACTION.ADD,
                          subject: constants.PERMISSIONS_MODEL.REPEATERS
                        })
                      "
                      :selectedUnitsData="selectedUnitsData"
                      :selectedUnitByUser="selectedUnitByUser"
                      :isClear="isClear"
                      :notification="notification"
                      :unitFun="unitFun"
                      :getUnitLookupCount="getUnitLookupCount"
                      :totalLookUpCount="totalLookUpCount"
                      :removedSelectedItem="removedSelectedItem"
                      :accountId="selectedAccount"
                      :componentKey="'repeater'"
                    ></NotificationUnitsSelection>
                  </div>
                </div>

                <b-tabs
                  pills
                  class="mt-2 unit-choice-tab"
                  v-if="repeater.selected_units_or_groups.unit_choice != 'all'"
                >
                  <b-tab>
                    <template #title>
                      <feather-icon
                        icon="UserIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span class="d-none d-sm-inline">{{
                        $t("repeaters.Units")
                      }}</span>
                    </template>
                    <UnitsModal
                      v-if="units && units.length"
                      v-model="units"
                      :totalUnits="totalUnits"
                      :removeUnits="removeUnits"
                      :getRepeatersUnits="getRepeatersUnits"
                    />
                  </b-tab>
                  <b-tab>
                    <template #title>
                      <feather-icon
                        icon="UserIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span class="d-none d-sm-inline">{{
                        $t("repeaters.Group_of_Units")
                      }}</span>
                    </template>
                    <UnitGroupInfoModal
                      v-if="unitGroups && unitGroups.length"
                      v-model="unitGroups"
                      :getRepeatersUnitGroups="getRepeatersUnitGroups"
                      :totalUnitGroups="totalUnitGroups"
                      :removeGroups="removeGroups"
                    />
                  </b-tab>
                </b-tabs>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div class="d-flex justify-content-start mb-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1"
                  :disabled="invalid || isInvalidHost"
                  v-if="
                    checkAbility({
                      action: constants.PERMISSIONS_ACTION.ADD,
                      subject: constants.PERMISSIONS_MODEL.REPEATERS
                    })
                  "
                  @click="save"
                >
                  {{ $t("user.Save") }}
                </b-button>

                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  class=""
                  @click="addForm"
                  v-show="
                    isHideAdd &&
                    checkAbility({
                      action: constants.PERMISSIONS_ACTION.ADD,
                      subject: constants.PERMISSIONS_MODEL.REPEATERS
                    })
                  "
                >
                  <feather-icon icon="PlusIcon" />{{
                    $t("repeaters.AddOneMoreServer")
                  }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormRadio,
  BFormCheckbox,
  BTabs,
  BTab,
  BCardText,
  BInputGroup,
  BPagination
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import constants from "@/utils/constants";
import UnitService from "@/libs/api/unit-service";
import UnitsModal from "@/layouts/components/Repeaters/UnitsModal.vue";
import { required, min, max } from "@validations";
import isHost from "is-valid-hostname";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import RepeaterTests from "@/layouts/components/Repeaters/RepeaterTest.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UnitGroupInfoModal from "@/layouts/components/Repeaters/UnitGroupInfoModal.vue";
import NotificationUnitsSelection from "@/views/Notification/NotificationUnitsSelection.vue";
export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BCard,
    vSelect,

    BFormRadio,
    BFormCheckbox,
    BTabs,
    BTab,
    BCardText,
    UnitsModal,
    BPagination,
    UnitGroupInfoModal,
    BForm,
    NotificationUnitsSelection,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    RepeaterTests
    // Search
  },
  directives: {
    Ripple
  },
  mixins: [heightTransition],
  data() {
    return {
      units: [],
      unitGroups: [],
      totalLookUpCount: 10,
      isInvalidHost: false,
      removedSelectedItem: {},
      isEditable: true,
      selectedUnitsData: [],
      selectedUnitByUser: [],
      totalUnitGroups: 0,
      totalUnits: 0,
      selectedUnselectedItems: {
        unit: { selected: [], unselected: [] },
        group: { selected: [], unselected: [] }
      },

      isClear: false,

      notification: {}
    };
  },
  async mounted() {
    if (
      this.repeater &&
      this.repeater.selected_units_or_groups &&
      this.repeater.selected_units_or_groups.unit_choice == "selected"
    ) {
      await this.getRepeatersUnits(12, 1);
      await this.getRepeatersUnitGroups(12, 1);
      await this.unitFun(
        { selected: [...this.unitGroups, ...this.units], unSelected: [] },
        true
      );
    }
    // if (this.repeater && this.repeater.host) {
    //   this.validHost();
    // }
  },
  props: [
    "repeater",
    "available_protocols",
    "max_repeaters",
    "addForm",
    "deleteForm",
    "isLastForm",
    "index",
    "saveRepeaters",
    "isHideAdd",
    "selectedAccount",
    "repeaters"
  ],
  watch: {
    async "repeater.id"() {
      await this.getRepeatersUnits(12, 1);
      await this.getRepeatersUnitGroups(12, 1);
      await this.unitFun(
        { selected: [...this.unitGroups, ...this.units], unSelected: [] },
        true
      );
    },
    selectedAccount() {
      this.selectedUnitsData = [];
      this.selectedUnitByUser = [];
      this.totalUnitGroups = 0;
      this.totalUnits = 0;
      this.units = [];
      this.unitGroups = [];
      this.selectedUnselectedItems = {
        unit: { selected: [], unselected: [] },
        group: { selected: [], unselected: [] }
      };
    }
  },
  methods: {
    getUnitLookupCount() {},
    unitFun(data, isAlreadySave) {
      data.selected.forEach((element) => {
        if (element && element.source == "GROUP") {
          if (
            !this.selectedUnselectedItems.group.selected.includes(element.id)
          ) {
            this.selectedUnselectedItems.group.selected.push(element.id);
          }
        } else if (element && element.source == "UNIT") {
          if (
            !this.selectedUnselectedItems.unit.selected.includes(element.id)
          ) {
            if (!element.is_selected) {
            }
            this.selectedUnselectedItems.unit.selected.push(element.id);
          }
        }
      });
      if (this.repeater.id) {
        data.unSelected.forEach((element) => {
          if (element && element.source == "GROUP") {
            if (
              !this.selectedUnselectedItems.group.unselected.includes(
                element.id
              )
            ) {
              this.selectedUnselectedItems.group.unselected.push(element.id);
            }
            if (
              this.selectedUnselectedItems.group.selected.includes(element.id)
            ) {
              this.selectedUnselectedItems.group.selected =
                this.selectedUnselectedItems.group.selected.filter(
                  (e) => e.id === element.id
                );
            }
          } else if (element && element.source == "UNIT") {
            if (
              !this.selectedUnselectedItems.unit.unselected.includes(element.id)
            ) {
              this.selectedUnselectedItems.unit.unselected.push(element.id);
            }
            if (
              this.selectedUnselectedItems.unit.selected.includes(element.id)
            ) {
              this.selectedUnselectedItems.unit.selected =
                this.selectedUnselectedItems.unit.selected.filter(
                  (e) => e.id === element.id
                );
            }
          }
        });
        if (data.unSelected && data.unSelected.length === 0) {
          this.selectedUnselectedItems.group.unselected = [];
          this.selectedUnselectedItems.unit.unselected = [];
        }
      }

      // if (!isAlreadySave) {
      const unitsdata = [
        {
          type: "GROUP",
          items: this.selectedUnselectedItems.group
        },
        {
          type: "UNIT",
          items: this.selectedUnselectedItems.unit
        }
      ];
      // this.selected_units_or_groups = {
      //   all: false,
      //   unit: this.selectedUnselectedItems.unit,
      //   unit_groups: this.selectedUnselectedItems.group
      // };
      this.unitGroups = data.selected.filter((e) => e.source == "GROUP");
      this.units = data.selected.filter((e) => e.source == "UNIT");
      // }
      this.selectedUnitByUser = unitsdata;
      this.selectedUnitsData = data.selected;
    },
    removeUnits(unit) {
      for (let index = 0; index < this.units.length; index++) {
        if (unit.source == "UNIT") {
          if (this.selectedUnselectedItems.unit.selected.includes(unit.id)) {
            this.selectedUnselectedItems.unit.unselected.push(unit.id);
            this.selectedUnselectedItems.unit.selected =
              this.selectedUnselectedItems.unit.selected.filter(
                (e) => e !== unit.id
              );

            this.totalLookUpCount = this.totalLookUpCount - 1;
            this.removedSelectedItem = unit;
          }
        }
      }

      this.selectedUnitsData = this.selectedUnitsData.filter(
        (e) => e.id !== unit.id
      );

      this.units = this.this.selectedUnitsData.filter(
        (e) => e.source == "UNIT"
      );
    },
    removeGroups(group) {
      for (let index = 0; index < this.unitGroups.length; index++) {
        if (group.source == "GROUP") {
          if (this.selectedUnselectedItems.group.selected.includes(group.id)) {
            this.selectedUnselectedItems.group.unselected.push(group.id);
            this.selectedUnselectedItems.group.selected =
              this.selectedUnselectedItems.group.selected.filter(
                (e) => e !== group.id
              );

            this.totalLookUpCount = this.totalLookUpCount - 1;
            this.removedSelectedItem = group;
          }
        }
      }

      this.selectedUnitsData = this.selectedUnitsData.filter(
        (e) => e.id !== group.id
      );
      this.unitGroups = this.selectedUnitsData.filter(
        (e) => e.source == "GROUP"
      );
    },
    deleteRepeaters(index, id) {
      this.unitGroups = [];
      this.totalUnitGroups = 0;
      this.units = [];
      this.totalUnits = 0;
      this.deleteForm(index, id);
    },
    removeDuplicates(arr) {
      let unique = [];
      arr.forEach((element) => {
        if (!unique.includes(element)) {
          unique.push(element);
        }
      });
      return unique;
    },
    save() {
      if (this.repeater && this.repeater.host) {
        const isInValidHost = this.validHost();
        if (isInValidHost) {
          return;
        }
      }
      this.selectedUnselectedItems.group.selected = this.removeDuplicates(
        this.selectedUnselectedItems.group.selected
      );
      this.selectedUnselectedItems.group.unselected = this.removeDuplicates(
        this.selectedUnselectedItems.group.unselected
      );
      this.selectedUnselectedItems.unit.selected = this.removeDuplicates(
        this.selectedUnselectedItems.unit.selected
      );
      this.selectedUnselectedItems.unit.unselected = this.removeDuplicates(
        this.selectedUnselectedItems.unit.unselected
      );

      if (
        this.repeater.host &&
        this.repeater.protocol &&
        ((this.repeater.protocol === "MQTT" && this.repeater.topic) ||
          (this.repeater.protocol !== "MQTT" && this.repeater.port))
      ) {
        const repeaterData = {
          id: this.repeater.id || undefined,
          host: this.repeater.host,
          port: Number(this.repeater.port),
          topic: this.repeater.topic,
          protocol: this.repeater.protocol,
          selected_units_or_groups: {
            unit_choice: this.repeater.selected_units_or_groups.unit_choice,
            unit:
              this.repeater.selected_units_or_groups.unit_choice === "selected"
                ? this.selectedUnselectedItems.unit
                : { selected: [], unselected: [] },
            unit_groups:
              this.repeater.selected_units_or_groups.unit_choice === "selected"
                ? this.selectedUnselectedItems.group
                : { selected: [], unselected: [] }
          }
        };
        this.saveRepeaters(repeaterData, this.index);
      }
    },
    async getRepeatersUnits(size) {
      try {
        //  await this.validationForm();
        if (!this.repeater.id) {
          return;
        }
        let response = await new UnitService().getRepeatersUnits({
          id: this.repeater.id,
          page: 1,
          page_size: size,
          account_id: this.selectedAccount || this.$route.query.account_id
        });

        if (response && response.data) {
          this.show = false;
          const unitList = response.data.list;
          this.units = [];
          this.units = unitList.map((e) => {
            e.source = "UNIT";
            e.labels = e.groups;
            return e;
          });
          this.units = this.removeDuplicates(this.units);
          this.totalUnits = response.data.pagination.total_records;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        console.log(err);
        this.show = false;
      }
    },
    async getRepeatersUnitGroups(size, page) {
      try {
        //  await this.validationForm();
        if (!this.repeater.id) {
          return;
        }
        let response = await new UnitService().getRepeatersUnitGroups({
          id: this.repeater.id,
          page: page,
          page_size: size,
          account_id: this.selectedAccount || this.$route.query.account_id
        });

        if (response && response.data) {
          this.show = false;
          const unitList = response.data.list;
          this.totalUnitGroups = response.data.pagination.total_records;
          this.unitGroups = [];
          this.unitGroups = unitList.map((e) => {
            e.source = "GROUP";
            return e;
          });
          this.unitGroups = this.removeDuplicates(this.unitGroups);
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        console.log(err);
        this.show = false;
      }
    },
    close() {},
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    validHost() {
      if (this.repeater.host) {
        const flag = isHost(this.repeater.host);
        if (!flag) {
          this.isInvalidHost = true;
        } else {
          this.isInvalidHost = false;
        }
        return this.isInvalidHost;
      }
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.repeater-form {
  transition: 0.35s height;
}
.mr-t-28 {
  margin-top: 28px;
}
.card .card.unit-card {
  box-shadow: 0px 5px 20px rgba(34, 41, 47, 0.1) !important;
  .unit-info-col {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.radio-row {
  min-height: 50px;
  .custom-radio {
    margin-top: 0px;
  }
}
.ww-50 {
  width: 50%;
  .search-filter {
    width: 100%;
    margin-right: 0px;
  }
}
.repeater-row-radio {
  .mt-10.repeater-select-group {
    .form-group {
      margin-bottom: 0px;
    }
  }
}
.select-protocol {
  .v-select {
    width: 100%;
  }
}
@media only screen and (max-width: 667px) {
  .radio-row {
    flex-wrap: wrap;
  }
  .ww-50 {
    width: 100%;
    margin-top: 15px;
  }
  .mobile-view-delete {
    .mr-t-28 {
      margin-top: 0.5rem;
    }
    .btn-icon.rounded-circle {
      height: 45px;
      width: 45px;
    }
  }
}
.repeater-select-group .unit-search-comp .group-selection {
  .pagination {
    margin-bottom: 10px !important;
  }
}
</style>
