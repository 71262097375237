<template>
  <div class="repeater-scroll">
    <b-card style="margin-bottom: 10px; max-height: 100px">
      <div class="d-flex justify-content-end mobile-hidden align-items-center">
        <div
          style="max-width: 350px"
          class="search-outer-alert padding-btm-0 mb-1 mt-1"
          v-if="
            checkAbility({
              action: constants.PERMISSIONS_ACTION.REPEATER_SUB_ACCOUNT,
              subject: constants.PERMISSIONS_MODEL.REPEATERS
            })
          "
        >
          <AccountSearch
            :all="true"
            class="unit-search-v2"
            v-model="selectedAccount"
          />
        </div></div
    ></b-card>
    <div v-for="(repeater, index) in repeaters.list" :key="index" ref="row">
      <Repeaters
        :selectedAccount="selectedAccount"
        :repeater="repeater"
        :index="index"
        :repeaters="repeaters"
        :available_protocols="repeaters.available_protocols"
        :addForm="addForm"
        :deleteForm="deleteForm"
        :isLastForm="isLastForm"
        :saveRepeaters="saveRepeaters"
        :isHideAdd="
          repeaters.list.length < repeaters.max_repeaters &&
          repeaters.list.length === index + 1
        "
      ></Repeaters>
    </div>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BCard
} from "bootstrap-vue";
import Repeaters from "@/layouts/components/Repeaters/Repeaters.vue";
import UnitService from "@/libs/api/unit-service";
import AccountSearch from "@/views/Unit/Unit-V2/AccountSearch.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import constants from "@/utils/constants";
export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BCard,
    Repeaters,
    AccountSearch

    // Search
  },

  data() {
    return {
      repeaters: {
        max_repeaters: 3,
        available_protocols: ["HTTP", "TCP"],
        list: []
      },
      repeatersTemp: [],
      selectedAccount:
        this.$route.query.account_id || localStorage.getItem("USER_ACCOUNT_ID")
    };
  },
  mounted() {
    this.getRepeaters();
  },
  watch: {
    selectedAccount(val) {
      this.repeaters.list = [
        {
          host: "",
          port: "",
          protocol: "TCP",
          selected_units_or_groups: {
            unit_choice: "all",
            unit: {
              selected: [],
              unselected: []
            },
            unit_groups: { selected: [], unselected: [] }
          }
        }
      ];

      this.getRepeaters();
    }
  },
  methods: {
    addForm() {
      if (this.repeaters.list.length < this.repeaters.max_repeaters)
        this.repeaters.list.push({
          host: "",
          port: "",
          protocol: "TCP",
          selected_units_or_groups: {
            unit_choice: "all",
            unit: {
              selected: [],
              unselected: []
            },
            unit_groups: { selected: [], unselected: [] }
          }
        });
    },
    deleteForm(index, id) {
      this.repeaters.list.splice(index, 1);
      this.repeatersTemp.slice(index, 1);
      if (id) {
        const repeaterData = [];
        this.repeaters.list.forEach((repeater, index) => {
          const dataa = {
            id: repeater.id || undefined,
            host: repeater.host,
            port: Number(repeater.port),
            topic: repeater.topic,
            protocol: repeater.protocol,
            selected_units_or_groups: {
              unit_choice: repeater.selected_units_or_groups.unit_choice,
              unit: { selected: [], unselected: [] },
              unit_groups: { selected: [], unselected: [] }
            }
          };
          repeaterData.push(dataa);
        });

        this.saveRepeaters(null, null);
      } else {
        if (this.repeaters.list && this.repeaters.list.length === 0) {
          this.addForm();
        }
        if (!id) {
          return;
        }
      }
    },
    isLastForm(index) {
      return index === this.repeaters.list.length - 1;
    },
    async getRepeaters() {
      try {
        //  await this.validationForm();
        const req = {
          account_id: this.selectedAccount || this.$route.query.account_id,
          page: 1,
          page_size: 3
        };
        let response = await new UnitService().getRepeaters(req);

        if (response && response.data) {
          this.show = false;
          this.repeaters = response.data;
          this.repeatersTemp = response.data.list;
          if (
            this.repeaters &&
            (!this.repeaters.list || !this.repeaters.list.length)
          ) {
            this.repeaters.list = [
              {
                host: "",
                port: "",
                protocol: "TCP",
                selected_units_or_groups: {
                  unit_choice: "all",
                  unit: {
                    selected: [],
                    unselected: []
                  },
                  unit_groups: { selected: [], unselected: [] }
                }
              }
            ];
          }
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
      }
    },

    async saveRepeaters(data, index) {
      try {
        //  await this.validationForm();
        const repeaterReq = this.repeatersTemp.map((e, i) => {
          e.selected_units_or_groups = {
            unit_choice: e.selected_units_or_groups.unit_choice,
            unit: { selected: [], unselected: [] },
            unit_groups: { selected: [], unselected: [] }
          };

          return e;
        });
        if (data) {
          repeaterReq[index] = data;
        }

        let response = await new UnitService().saveRepeaters({
          repeaters: repeaterReq,
          account_id: this.selectedAccount || this.$route.query.account_id
        });

        if (response && response.data) {
          this.show = false;
          //  this.alert = response.data || {};
          let message = this.$t("repeaters.AddSuccessfully");
          // if (data && id) {
          //   message = this.$t("repeaters.UpdateSuccessfully");
          // }
          this.$toast({
            component: ToastificationContent,
            props: {
              text: message,
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.getRepeaters();
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          this.getRepeaters();
        }
      } catch (err) {
        this.getRepeaters();
        this.show = false;
      }
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.repeater-form {
  transition: 0.35s height;
}
.mr-t-28 {
  margin-top: 28px;
}
.card .card.unit-card {
  box-shadow: 0px 5px 20px rgba(34, 41, 47, 0.1) !important;
  .unit-info-col {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.radio-row {
  min-height: 50px;
  .custom-radio {
    margin-top: 0px;
  }
}
.ww-50 {
  width: 50%;
  .search-filter {
    width: 100%;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 667px) {
  .radio-row {
    flex-wrap: wrap;
  }
  .ww-50 {
    width: 100%;
    margin-top: 15px;
  }
}
.repeater-scroll {
  height: calc(100vh - 120px);
  overflow-y: auto;
  overflow-x: hidden;
  .tab-content .card-body {
    min-height: auto !important;
  }
}
</style>
