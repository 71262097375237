<template>
  <div>
    <b-button @click="showPopup" class="btn mr-t-28" variant="outline-primary">
      <span class="d-sm-inline">{{ $t("repeaters.Test") }}</span>
    </b-button>

    <b-modal
      :id="id ? id : 'open-test-popup'"
      centered
      no-close-on-esc
      no-close-on-backdrop
      size="lg"
      modal-class="no-header-modal "
      :hide-header="true"
    >
      <div
        style="height: 50vh; overflow-y: auto; margin-top: 1.5rem !important"
      >
        <!-- <span v-if="!testData || !testData.length">
          <b-skeleton></b-skeleton> <b-skeleton></b-skeleton>
          <b-skeleton></b-skeleton>
        </span> -->

        <ul>
          <li v-for="(item, index) in testData" :key="index">
            <span v-html="item"> </span>
            <br />
            <br />
          </li>
        </ul>
      </div>
      <div class="d-flex justify-content-center mb-2">
        <!-- <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          :disabled="!isSave"
          @click="saveSensors"
        >
          {{ $t("user.Save") }}
        </b-button> -->
        <b-button variant="outline-secondary" class="mt-2" @click="closePopup">
          {{ $t("user.close") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BButton, VBTooltip, BModal, BSkeleton } from "bootstrap-vue";

import UnitService from "@/libs/api/unit-service";
import SocketIOService from "@/libs/socket/sockets";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import constants from "@/utils/constants";

export default {
  components: {
    BModal,
    BButton,
    BSkeleton
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      testData: []
    };
  },
  props: ["id", "selectedAccount"],
  mounted() {},
  computed: {},
  beforeDestroy() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  },
  methods: {
    async showPopup() {
      try {
        if (!this.id) {
          return;
        }
        const message = this.$t("repeaters.TestingInitiated");
        this.$bvModal.show(this.id ? this.id : "open-test-popup");
        this.testData = [
          `<span><b>${this.formattedDateTime(
            new Date(),
            constants.DATE_TIME_FORMAT
          )}</b> ${message}`
        ];
        this.subscribeUnitData();
        let response = await new UnitService().testRepeater({
          id: this.id,
          account_id: this.selectedAccount || this.$route.query.account_id
        });
        if (response && response.data) {
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          this.closePopup();
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.closePopup();
      }
    },
    closePopup() {
      this.disconnectSocket();
      this.testData = [];
      this.$bvModal.hide(this.id ? this.id : "open-test-popup");
    },
    async subscribeUnitData() {
      try {
        if (!this.id && this.socket) {
          this.socket.disconnect();
          this.socket = null;
        }
        if (!this.socket) {
          this.socket = new SocketIOService();
          await this.socket.setupSocketConnection();
        }
        if (this.id) {
          const ass = this;
          this.socket.subscribeEventWithFunction(`RP_${this.id}`, (data) => {
            try {
              ass.testData.unshift(
                `<span><b>${this.formattedDateTime(
                  data.timestamp,
                  constants.DATE_TIME_FORMAT
                )}</b> ${data.message}`
              );
            } catch (err) {
              console.log(err);
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
      // else {
      //   this.socket.unsubscribeEventWithFunction(`UM_${this.UnitInfo.id}`);
      // }
    },

    disconnectSocket() {
      if (this.socket) {
        this.socket.disconnect();
        this.socket = null;
      }
    }
  }
};
</script>

<style lang="scss"></style>
