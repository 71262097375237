<template>
  <b-card class="unit-card">
    <b-row>
      <b-col md="12" class="unit-info-col">
        <div class="d-flex flex-wrap">
          <UnitInfo
            v-for="unit in units"
            :key="unit.id"
            :unit="unit"
            :removeUnits="removeUnits"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="d-flex justify-content-end">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalUnits"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BAlert,
  BMedia,
  BMediaBody,
  BRow,
  BCol,
  BCard,
  BAvatar,
  BPagination
} from "bootstrap-vue";
import UnitInfo from "@/layouts/components/Repeaters/UnitInfo.vue";
import Badge from "@/layouts/components/Badge.vue";
export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BMedia,
    BMediaBody,
    BAvatar,
    BCard,
    Badge,
    BPagination,
    UnitInfo
  },
  data() {
    return {
      currentPage: 1,
      perPage: 8,

      units: []
    };
  },
  props: ["value", "removeUnits", "totalUnits", "getRepeatersUnits"],
  mounted() {
    this.getUnits();
  },
  watch: {
    value() {
      this.getUnits();
    },
    currentPage() {
      this.getRepeatersUnits(12, this.currentPage);
    }
  },
  methods: {
    getUnits() {
      this.units = this.value || [];
    }
  }
};
</script>

<style lang="scss">
.alert-view {
  width: auto;
  min-width: 33.33%;
  padding-left: 7px;
  padding-right: 7px;
  margin-bottom: 6px;
  .alert-white {
    border: 1px solid rgba(110, 107, 123, 0.12);
    box-shadow: 0px 5px 20px rgba(34, 41, 47, 0.1);

    .b-avatar .b-avatar-img img {
      object-fit: contain;
    }
    .media-body {
      align-self: center;
    }
    .close {
      top: 0;
      bottom: 0;
    }
  }
  .pr-10 {
    padding-right: 15px;
  }
}
.fade-animation {
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}
.zero-class {
  height: 0;
  width: 0 !important;
  padding: 0 !important;
}
@media only screen and (max-width: 667px) {
  .alert-view {
    width: 100%;
  }
  .mr-t-28 {
    margin-top: 0px;
  }
}
</style>
