var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alert-view fade-animation",class:{ 'zero-class': !_vm.showParentDiv }},[_c('b-alert',{staticClass:"mb-0",attrs:{"dismissible":_vm.checkAbility({
        action: _vm.constants.PERMISSIONS_ACTION.ADD,
        subject: _vm.constants.PERMISSIONS_MODEL.REPEATERS
      }),"fade":"","variant":"white"},on:{"dismissed":_vm.handleDismiss},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}},[_c('div',{staticClass:"alert-body"},[_c('b-media',{staticClass:"align-item-center",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('span',{staticClass:"unit-list-map-icon-parent"},[_c('b-avatar',{attrs:{"size":"40","variant":"primary","text":"TA"}})],1)]},proxy:true}])},[_c('div',{staticClass:"d-flex justify-content-between pr-10"},[_c('h6',{staticClass:"mb-0 d-flex align-item-center text-primary line-26"},[_c('span',{staticClass:"u-x-name"},[_vm._v(" "+_vm._s(_vm.unitGroup.name))])]),(
              _vm.unitGroup.units &&
              _vm.unitGroup.units.list &&
              _vm.unitGroup.units.list.length
            )?_c('span',[_c('Badge',{attrs:{"showAllTag":false,"tags":_vm.unitGroup.units.list,"showTextInBadge":Number(7),"event":{ id: _vm.unitGroup.id + _vm.unitGroup.name },"maxTagShow":Number(2)}})],1):_vm._e()])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }