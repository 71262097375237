<template>
  <div
    class="alert-view fade-animation"
    :class="{ 'zero-class': !showParentDiv }"
  >
    <b-alert
      v-model="showAlert"
      :dismissible="
        checkAbility({
          action: constants.PERMISSIONS_ACTION.ADD,
          subject: constants.PERMISSIONS_MODEL.REPEATERS
        })
      "
      @dismissed="handleDismiss"
      fade
      class="mb-0"
      variant="white"
    >
      <div class="alert-body">
        <b-media class="align-item-center">
          <template #aside>
            <span class="unit-list-map-icon-parent">
              <b-avatar
                size="40"
                variant="primary"
                src="http://polygon.getgps.online:8102/images/icons/truck/red_truck_icon.svg"
              />
            </span>
          </template>
          <div class="d-flex justify-content-between pr-10">
            <h6 class="mb-0 d-flex align-item-center text-primary line-26">
              <span class="u-x-name"> {{ unit.name }}</span>
            </h6>
            <span
              v-if="unit.labels && unit.labels.list && unit.labels.list.length"
            >
              <Badge
                :showAllTag="false"
                :tags="unit.labels.list"
                :showTextInBadge="Number(7)"
                :event="{ id: unit.id + unit, name }"
                :maxTagShow="Number(2)"
              ></Badge>
            </span>
          </div>
        </b-media>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { BAlert, BMedia, BMediaBody, BAvatar } from "bootstrap-vue";
import Badge from "@/layouts/components/Badge.vue";
import constants from "@/utils/constants";
export default {
  components: {
    BAlert,
    BMedia,
    BMediaBody,
    BAvatar,
    Badge
  },
  data() {
    return { showParentDiv: true, showAlert: true };
  },
  props: ["unit", "removeUnits"],
  mounted() {},
  methods: {
    handleDismiss() {
      this.showParentDiv = false;
      this.showAlert = false;
      this.removeUnits(this.unit);
    }
  }
};
</script>

<style lang="scss">
.alert-view {
  width: auto;
  min-width: 33.33%;
  padding-left: 7px;
  padding-right: 7px;
  margin-bottom: 6px;
  .alert-white {
    border: 1px solid rgba(110, 107, 123, 0.12);
    box-shadow: 0px 5px 20px rgba(34, 41, 47, 0.1);

    .b-avatar .b-avatar-img img {
      object-fit: contain;
    }
    .media-body {
      align-self: center;
    }
    .close {
      top: 0;
      bottom: 0;
    }
  }
  .pr-10 {
    padding-right: 15px;
  }
}
.fade-animation {
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}
.zero-class {
  height: 0;
  width: 0 !important;
  padding: 0 !important;
}
.line-26 {
  line-height: 26px;
  padding-right: 10px;
  align-items: center;
}
@media only screen and (max-width: 667px) {
  .alert-view {
    width: 100%;
  }
  .mr-t-28 {
    margin-top: 0px;
  }
}
.alert-body {
  .media-aside.align-self-start {
    margin: auto;
    margin-right: 10px;
  }
  .media-body {
    h6 .u-x-name {
      width: 135px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block !important;
    }
  }
  .media.align-item-center.event-Tag .media-body {
    justify-content: flex-end;
  }
}
</style>
