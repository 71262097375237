var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticStyle:{"margin-bottom":"10px"}},[_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{ref:"form",staticClass:"repeater-form",style:({ height: _vm.trHeight })},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('repeaters.Host')}},[_c('validation-provider',{attrs:{"name":"Host","vid":"Name","rules":{
                  required: true
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 || _vm.isInvalidHost ? 'is-invalid' : null},[_c('b-form-input',{class:errors.length == 0 && _vm.repeater.host && !_vm.isInvalidHost
                        ? 'is-valid'
                        : null,attrs:{"id":"item-name","type":"text","disabled":!_vm.checkAbility({
                        action: _vm.constants.PERMISSIONS_ACTION.ADD,
                        subject: _vm.constants.PERMISSIONS_MODEL.REPEATERS
                      }),"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('repeaters.host_placeholder')},on:{"input":_vm.validHost},model:{value:(_vm.repeater.host),callback:function ($$v) {_vm.$set(_vm.repeater, "host", $$v)},expression:"repeater.host"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : _vm.isInvalidHost ? _vm.$t("repeaters.InvalidHostMessage") : ""))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[(_vm.repeater.protocol != 'MQTT')?_c('b-form-group',{attrs:{"label":_vm.$t('repeaters.Port')}},[_c('validation-provider',{attrs:{"name":"Port","vid":"Port","rules":{
                  required: _vm.repeater.protocol != 'MQTT'
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{class:errors.length == 0 && _vm.repeater.port ? 'is-valid' : null,attrs:{"id":"item-name","type":"number","disabled":!_vm.checkAbility({
                        action: _vm.constants.PERMISSIONS_ACTION.ADD,
                        subject: _vm.constants.PERMISSIONS_MODEL.REPEATERS
                      }),"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('repeaters.port_placeholder')},model:{value:(_vm.repeater.port),callback:function ($$v) {_vm.$set(_vm.repeater, "port", $$v)},expression:"repeater.port"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1):_c('b-form-group',{attrs:{"label":((_vm.$t('repeaters.Topic')) + " *")}},[_c('validation-provider',{attrs:{"name":"Topic","vid":"Topic","rules":{
                  required: _vm.repeater.protocol == 'MQTT'
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{attrs:{"id":"item-name","type":"text","disabled":!_vm.checkAbility({
                        action: _vm.constants.PERMISSIONS_ACTION.ADD,
                        subject: _vm.constants.PERMISSIONS_MODEL.REPEATERS
                      }),"placeholder":_vm.$t('repeaters.topic_placeholder')},model:{value:(_vm.repeater.topic),callback:function ($$v) {_vm.$set(_vm.repeater, "topic", $$v)},expression:"repeater.topic"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"select-protocol",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('repeaters.Protocol')}},[_c('validation-provider',{attrs:{"name":"protocol","vid":"protocol","rules":{
                  required: true
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('v-select',{attrs:{"label":"title","clearable":false,"disabled":!_vm.checkAbility({
                        action: _vm.constants.PERMISSIONS_ACTION.ADD,
                        subject: _vm.constants.PERMISSIONS_MODEL.REPEATERS
                      }),"options":_vm.available_protocols,"placeholder":_vm.$t('repeaters.protocol_placeholder')},model:{value:(_vm.repeater.protocol),callback:function ($$v) {_vm.$set(_vm.repeater, "protocol", $$v)},expression:"repeater.protocol"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1),(
              _vm.checkAbility({
                action: _vm.constants.PERMISSIONS_ACTION.ADD,
                subject: _vm.constants.PERMISSIONS_MODEL.REPEATERS
              })
            )?_c('b-col',{staticClass:"mb-50 mobile-view-delete",attrs:{"md":"1"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon rounded-circle mr-10-1 mr-t-28",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteRepeaters(_vm.index, _vm.repeater.id)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1)],1):_vm._e(),_c('b-col',{staticClass:"mb-50",attrs:{"md":"5"}},[(
                _vm.repeater.id &&
                _vm.checkAbility({
                  action: _vm.constants.PERMISSIONS_ACTION.ADD,
                  subject: _vm.constants.PERMISSIONS_MODEL.REPEATERS
                })
              )?_c('RepeaterTests',{attrs:{"selectedAccount":_vm.selectedAccount,"id":_vm.repeater.id}}):_vm._e()],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',[_c('div',{staticClass:"d-flex align-items-center radio-row repeater-row-radio"},[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-radio',{attrs:{"name":"some-radios","value":"all"},model:{value:(_vm.repeater.selected_units_or_groups.unit_choice),callback:function ($$v) {_vm.$set(_vm.repeater.selected_units_or_groups, "unit_choice", $$v)},expression:"repeater.selected_units_or_groups.unit_choice"}},[_vm._v(" "+_vm._s(_vm.$t("repeaters.All_Units"))+" ")]),_c('b-form-radio',{attrs:{"name":"some-radios","value":"selected"},model:{value:(_vm.repeater.selected_units_or_groups.unit_choice),callback:function ($$v) {_vm.$set(_vm.repeater.selected_units_or_groups, "unit_choice", $$v)},expression:"repeater.selected_units_or_groups.unit_choice"}},[_vm._v(" "+_vm._s(_vm.$t("repeaters.Select_Units"))+" ")])],1),(
                    _vm.repeater.selected_units_or_groups.unit_choice != 'all'
                  )?_c('div',{staticClass:"ww-50 mt-10 repeater-select-group"},[_c('NotificationUnitsSelection',{attrs:{"isEditable":_vm.checkAbility({
                        action: _vm.constants.PERMISSIONS_ACTION.ADD,
                        subject: _vm.constants.PERMISSIONS_MODEL.REPEATERS
                      }),"selectedUnitsData":_vm.selectedUnitsData,"selectedUnitByUser":_vm.selectedUnitByUser,"isClear":_vm.isClear,"notification":_vm.notification,"unitFun":_vm.unitFun,"getUnitLookupCount":_vm.getUnitLookupCount,"totalLookUpCount":_vm.totalLookUpCount,"removedSelectedItem":_vm.removedSelectedItem,"accountId":_vm.selectedAccount,"componentKey":'repeater'}})],1):_vm._e()]),(_vm.repeater.selected_units_or_groups.unit_choice != 'all')?_c('b-tabs',{staticClass:"mt-2 unit-choice-tab",attrs:{"pills":""}},[_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"UserIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t("repeaters.Units")))])]},proxy:true}],null,true)},[(_vm.units && _vm.units.length)?_c('UnitsModal',{attrs:{"totalUnits":_vm.totalUnits,"removeUnits":_vm.removeUnits,"getRepeatersUnits":_vm.getRepeatersUnits},model:{value:(_vm.units),callback:function ($$v) {_vm.units=$$v},expression:"units"}}):_vm._e()],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"UserIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t("repeaters.Group_of_Units")))])]},proxy:true}],null,true)},[(_vm.unitGroups && _vm.unitGroups.length)?_c('UnitGroupInfoModal',{attrs:{"getRepeatersUnitGroups":_vm.getRepeatersUnitGroups,"totalUnitGroups":_vm.totalUnitGroups,"removeGroups":_vm.removeGroups},model:{value:(_vm.unitGroups),callback:function ($$v) {_vm.unitGroups=$$v},expression:"unitGroups"}}):_vm._e()],1)],1):_vm._e()],1)])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-start mb-1"},[(
                  _vm.checkAbility({
                    action: _vm.constants.PERMISSIONS_ACTION.ADD,
                    subject: _vm.constants.PERMISSIONS_MODEL.REPEATERS
                  })
                )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary","disabled":invalid || _vm.isInvalidHost},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("user.Save"))+" ")]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}},{name:"show",rawName:"v-show",value:(
                  _vm.isHideAdd &&
                  _vm.checkAbility({
                    action: _vm.constants.PERMISSIONS_ACTION.ADD,
                    subject: _vm.constants.PERMISSIONS_MODEL.REPEATERS
                  })
                ),expression:"\n                  isHideAdd &&\n                  checkAbility({\n                    action: constants.PERMISSIONS_ACTION.ADD,\n                    subject: constants.PERMISSIONS_MODEL.REPEATERS\n                  })\n                "}],attrs:{"variant":"outline-secondary"},on:{"click":_vm.addForm}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(_vm._s(_vm.$t("repeaters.AddOneMoreServer"))+" ")],1)],1)])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }